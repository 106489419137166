import { errors } from '../content'

export default {
  validateTotalVoiceLinesError: {
    message: errors.totalVoiceLines
  },
  validateVoiceMailCountError: {
    message: errors.voiceMailCount
  },
  validateVoicemailHuntGroupError: {
    message: errors.huntGroupVoiceMail
  },
  validateHuntGroupQtyError: {
    message: errors.huntGroupQty
  },
  validateVoiceMailError: {
    message: errors.missingVoiceMail
  },
  validateRingToNumberError: {
    message: errors.ringToNumber
  },
  validateVoiceLineDLNError: {
    message: errors.dlnCrcp
  },
  validateNotLocalPortDLNError: {
    message: errors.dlnNotLocalPort
  },
  validatePhoneNumbersError: {
    message: errors.phoneNumber
  },
  validateHuntGroupSequenceError: {
    message: errors.huntGroupSequence
  },
  validateCallingAreaError: {
    message: errors.callingArea
  },
  validateManualCrcpError: {
    message: errors.manualCrcpError
  },
  validateRateCenterError: {
    message: errors.rateCenter
  },
  validateCurrentProvidersError: {
    message: errors.missingCurrentProvider
  },
  validateE911NotificationEmailError: {
    message: errors.e911Email
  },
  validateVoiceIsValidError: {
    message: errors.voiceNotValidated
  },
  validateVoiceLineMoveStatusError: {
    message: errors.missingVoiceLineMoveStatus
  },
  validateTotalVoiceLinesForEPONError: {
    message: errors.totalVoiceLinesForEPONError
  },
  validateDuplicatePhonenumbersError: {
    message: errors.duplicatePhoneNumberMessage
  }
}
